<!--
* @Description 行业案例-江阴靖江长江隧道
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>
    <div class="con">
      <h1 class="hyalTitle">{{ $t("riverTunnelJyjj.section1_title") }}<br/>{{ $t("riverTunnelJyjj.section1_title2") }}</h1>
      <div class="conText mb40">
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content1") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content1_1") }}</p>
      </div>
      <div class="plr60">
        <div class="conImgH640 mb40">
          <img :src="imgs[0].url" alt="" />
        </div>
      </div>
      <div class="conText mb40">
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content2") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content2_1") }}</p>
      </div>

      <div class="plr60">
        <div class="conImgH600 mb40">
          <img :src="imgs[1].url" alt="" />
        </div>
      </div>
      <div class="conText pb40">
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content3") }}</p>
      </div>
      <div class="plr60">
        <div class="conImgH490 mb40">
          <img :src="imgs[2].url" alt="" />
        </div>
      </div>
      <div class="conText pb40">
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content4") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content5") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content6") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content7") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content8") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content9") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content10") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content11") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content12") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content13") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content14") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content15") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content16") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content17") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content18") }}</p>
        <p style="text-indent: 34px;">{{ $t("riverTunnelJyjj.section1_content19") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgs: [
        {
          url: require("../../../assets/images/industryImg/W020211231398298104137@2x.png"),
        },
        {
          url: require("../../../assets/images/industryImg/W020211231398298104137Bf@2x(1).png"),
        },
        {
          url: require("../../../assets/images/industryImg/江阴靖江3.png"),
        }
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.aboutContainer {
  background: #ffffff;
  .top {
    width: 100%;
    height: 400px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/industryImg/5fc6-2c9e6c6658af68005233c6c04d31f5701@2x(3).png");

    background-size: 100% 100%;

    background-repeat: no-repeat;
  }

  .con {
    width: 1200px;
    text-align: center;
    // padding: 0 360px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
